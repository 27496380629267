<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            แบบรายงานสรุปผลการดำเนินงาน การปฏิบัติงานตามขั้นตอนการปฏิบัติ
          </p>
          <!-- <p style="font-size: 24px" class="headtitle">
            เพื่อแก้ไขปัญหายาเสพติด แบบครบวงจร ตามยุทธศาสตร์ชาติ
          </p> -->
          <!-- <p style="font-size: 24px" class="headtitle">
            ตำรวจภูธรภาค {{ checklogin.station }}
          </p> -->
          <br />

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="สถานี"
                outlined
                v-model="data.station"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menufrom"
                v-model="menufrom"
                :close-on-content-click="false"
                :return-value.sync="data.datefrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.datefrom"
                    label="วันที่บันทึกข้อมูลล่าสุด"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="data.datefrom" no-title scrollable>
                  <v-btn text color="primary" @click="menufrom = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menufrom.save(data.datefrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ที่บันทึกข้อมูลล่าสุด"
                outlined
                v-model="data.staffName"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <h3 style="font-weight: 800">
              1. ผลการปฏิบัติงานของ หัวหน้าสถานี ตามแบบตรวจแนะนำ (1-7)
            </h3>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data.q_1_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.1 สรุปผลการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_1_1" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.2 จุดเด่นในการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_1_2" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.3 แนวทางการเสริมจุดเด่น</span>
              <v-textarea v-model="data.q_1_3" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.4 จุดที่ควรพัฒนา</span>
              <v-textarea v-model="data.q_1_4" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.5 แนวทางการพัฒนา</span>
              <v-textarea v-model="data.q_1_5" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <h3 style="font-weight: 800">
              2. ผลการปฏิบัติงาน ขั้นเตรียมการ ตามแบบตรวจแนะนำ (2.1 – 2.7 )
            </h3>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data.q_2_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>2.1 สรุปผลการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_2_1" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>2.2 จุดเด่นในการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_2_2" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>2.3 แนวทางการเสริมจุดเด่น</span>
              <v-textarea v-model="data.q_2_3" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>2.4 จุดที่ควรพัฒนา</span>
              <v-textarea v-model="data.q_2_4" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>2.5 แนวทางการพัฒนา</span>
              <v-textarea v-model="data.q_2_5" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <h3 style="font-weight: 800">
              3. ผลการปฏิบัติงาน ขั้นปฏิบัติการ ตามแบบตรวจแนะนำ (3.1 – 3.6 )
            </h3>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data.q_3_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>3.1 สรุปผลการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_3_1" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>3.2 จุดเด่นในการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_3_2" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>3.3 แนวทางการเสริมจุดเด่น</span>
              <v-textarea v-model="data.q_3_3" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>3.4 จุดที่ควรพัฒนา</span>
              <v-textarea v-model="data.q_3_4" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>3.5 แนวทางการพัฒนา</span>
              <v-textarea v-model="data.q_3_5" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <v-row>
            <h3 style="font-weight: 800">
              4. ผลการปฏิบัติงาน ขั้นส่งต่อความยั่งยืน ตามแบบตรวจแนะนำ (4.1 –
              4.4 )
            </h3>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data.q_4_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>4.1 สรุปผลการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_4_1" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>4.2 จุดเด่นในการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_4_2" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>4.3 แนวทางการเสริมจุดเด่น</span>
              <v-textarea v-model="data.q_4_3" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>4.4 จุดที่ควรพัฒนา</span>
              <v-textarea v-model="data.q_4_4" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>4.5 แนวทางการพัฒนา</span>
              <v-textarea v-model="data.q_4_5" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
            <v-col>
              <v-btn color="primary" @click="submit()">บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checklogin: [],
      // data: {
      //   station: "",
      //   datefrom: "",
      //   userId: "",
      // },
      data: {
        station: "",
        datefrom: "",
        userId: "",
        staffName: "",
        q_1_review: "",
        q_1_1: "",
        q_1_2: "",
        q_1_3: "",
        q_1_4: "",
        q_1_5: "",
        q_2_review: "",
        q_2_1: "",
        q_2_2: "",
        q_2_3: "",
        q_2_4: "",
        q_2_5: "",
        q_3_review: "",
        q_3_1: "",
        q_3_2: "",
        q_3_3: "",
        q_3_4: "",
        q_3_5: "",
        q_4_review: "",
        q_4_1: "",
        q_4_2: "",
        q_4_3: "",
        q_4_4: "",
        q_4_5: "",
      },
      menufrom: false,

      // saleCase: "",
      // possessionSaleCase: "",
      // possessionCase: "",
      // drugCase: "",
    };
  },
  async created() {
    var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
    // console.log(this.checklogin);
    this.data.userId = checklogin.id;
    this.data.station = checklogin.station;
    this.searchDropdown();
    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/summaryForms/findAll?station=` +
        this.data.station,
      {}
    );
    console.log("getstation11111", response.data.data[0]);
    if (response.data.data.length > 0) {
      // this.data = response.data.data[0];
      // this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);

      const responseUser = await this.axios.get(
        `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
      );
      console.log("responseUser", responseUser);
      if (responseUser.data.response_status == "SUCCESS") {
        console.log(responseUser);
        this.data = response.data.data[0];
        this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);
        this.data.staffName = responseUser.data.data.name;
        this.data.userId = checklogin.id;
        console.log(this.data.staffName);
      } else {
        this.data.staffName = "-";
      }
    }
    // const responseUser = await this.axios.get(
    //   `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
    // );
    // console.log("responseUser", responseUser);
    // if (responseUser.data.response_status == "SUCCESS") {
    //   console.log(responseUser);
    //   this.data.staffName = responseUser.data.data.name;
    //   console.log(this.data.staffName);
    // } else {
    //   this.data.staffName = "-";
    // }
  },
  methods: {
    convertDate(date) {
      return moment(String(date)).format("DD/MM/YYYY hh:mm");
    },
    cancel() {
      this.$router.push("/report");
    },
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        // item.searchText = item.station + " " + item.village;
        item.searchText = item.station;
      });
    },
    async submit() {
      console.log(this.data);
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        // console.log("เข้าเฉย");

        console.log("data", this.data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/summaryForms/create`,
          this.data
          // this.data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          this.loading = false;
          location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
          console.log(this.showImage1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
          // console.log(this.showImage1);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    showPicture3(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName3 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl3 = reader.result;
          this.showImage3 = URL.createObjectURL(element);
          // console.log(this.showImage1);
        };
      }
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    showPicture4(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName4 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl4 = reader.result;
          this.showImage4 = URL.createObjectURL(element);
          // console.log(this.showImage1);
        };
      }
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
